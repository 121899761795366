import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNumber, isString } from 'lodash-es';
import { EMPTY, first, map, Observable, switchMap } from 'rxjs';

import { Query, StateService } from '@locumsnest/core/src';

import { IUserEntity } from '../../interfaces/api/user-entity';
import { UpsertMultipleMessage, UpsertOneMessage } from './user.messages';
import { UserPersistenceService } from './user.persistence.service';
import { selectAllUsers, selectUser, selectUserEntities } from './user.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserService extends StateService<IUserEntity> {
  private store = inject(Store);
  protected persistenceService = inject(UserPersistenceService);

  getAll() {
    return this.store.pipe(select(selectAllUsers));
  }

  getOne(id: number) {
    return this.store.pipe(select(selectUser(id)));
  }

  fetch(query?: Query) {
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((user) => new UpsertOneMessage({ user })));

    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ users: results })));
  }

  loadOne(id) {
    const loadAction$ = this.fetch(id) as Observable<UpsertOneMessage>;
    return loadAction$;
  }

  loadMultiple(ids: number[]) {
    const loadAction$ = this.fetch({ id: ids }) as Observable<UpsertOneMessage>;
    return loadAction$;
  }

  createUpsertMultipleMessage(users) {
    return new UpsertMultipleMessage({ users });
  }

  getEntities() {
    return this.store.pipe(select(selectUserEntities));
  }

  loadOneIfNotExists(id: number) {
    return this.getOne(id).pipe(
      first(),
      switchMap((x) => {
        if (!x) {
          return this.loadOne(id);
        }
        return EMPTY;
      }),
    );
  }

  loadManyIfNotExists(ids: number[]) {
    return this.getEntities().pipe(
      first(),
      switchMap((entities) => {
        const uniqueIds = [...new Set(ids)];
        const idsToLoad: number[] = [];
        uniqueIds.forEach((id) => {
          if (!entities[id]) idsToLoad.push(id);
        });

        if (idsToLoad.length) return this.loadMultiple(idsToLoad);
        return EMPTY;
      }),
    );
  }
}
